import React from "react";
import TrackVisibility from "react-on-screen";
import Skill from "../Items/Skill";
import { randomID } from "../../utils/StringUtil";

const skillData = {
  skillContent:
    "Master the skills you use today and develop the skills you need tomorrow.",
  progressData: [
    {
      id: randomID(),
      name: "Javascript",
      percentage: 75,
    },
    {
      id: randomID(),
      name: "React.JS",
      percentage: 67,
    },
    {
      id: randomID(),
      name: "Node.JS",
      percentage: 68,
    },
    {
      id: randomID(),
      name: "NestJS",
      percentage: 67,
    },    
    {
      id: randomID(),
      name: "ES6",
      percentage: 87,
    },
    {
      id: randomID(),
      name: "Typescript",
      percentage: 70,
    },
    {
      id: randomID(),
      name: "Angular.JS",
      percentage: 46,
    },
    {
      id: randomID(),
      name: "PHP",
      percentage: 75,
    },
    {
      id: randomID(),
      name: "Wordpress",
      percentage: 71,
    },
    {
      id: randomID(),
      name: "Laravel Framework",
      percentage: 84,
    },
    {
      id: randomID(),
      name: "Codeigniter Framework",
      percentage: 68,
    },
    {
      id: randomID(),
      name: "Java",
      percentage: 40,
    },
    {
      id: randomID(),
      name: "Android",
      percentage: 67,
    },
    {
      id: randomID(),
      name: "Algorithm & Data Structure",
      percentage: 70,
    },
    {
      id: randomID(),
      name: "Mysql",
      percentage: 72,
    },
    {
      id: randomID(),
      name: "Linux",
      percentage: 73,
    },
    {
      id: randomID(),
      name: "GIT",
      percentage: 65,
    },
    {
      id: randomID(),
      name: "SMS Gateway",
      percentage: 68,
    },
    {
      id: randomID(),
      name: "Python",
      percentage: 47,
    },
  ],
};

function Skills() {
  return (
    <>
      <p className="mb-0">{skillData.skillContent}</p>
      <div className="mt-5">
        <div className="row -mt-50">
          {skillData.progressData.map((progress) => (
            <div className="col-md-6 mt-50" key={progress.id}>
              <TrackVisibility once>
                <Skill progress={progress} />
              </TrackVisibility>
            </div>
          ))}
        </div>
      </div>
    </>
  );
}

export default Skills;
