import React from "react";
import Service from "../Items/Service";
import { randomID } from "../../utils/StringUtil";

const servicesData = [
  {
    id: randomID(),
    name: "Web Development",
    content: "From back to front, you can count on me 🔥",
    icon: "icon-globe",
  },
  {
    id: randomID(),
    name: "Design",
    content: "Honestly, i used templates for all my devs. But i can customize it",
    icon: "icon-chemistry",
  },
  {
    id: randomID(),
    name: "SEO",
    content: "Make your web and app stands out from the crowd, i can do it",
    icon: "icon-rocket",
  },
];

function Services() {
  return (
    <div className="row -mt-20">
      {servicesData.map((service) => (
        <div className="col-md-4 col-sm-6 mt-20" key={service.id}>
          <Service service={service} />
        </div>
      ))}
    </div>
  );
}

export default Services;
