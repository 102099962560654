import React from "react";
import { Link as ScrollLink } from "react-scroll";

const aboutData = {
  cvpath: 'https://drive.google.com/file/d/1h77PvRwctr4dHuvhqVARZF_ntYLnFjuO/view?usp=sharing',
  image: "images/toor.jpg",
  name: "Reynaldi Rahmat",
  location: "Makassar, ID",
  birthday: "24 September, 1994",
  email: "reynaldyrahmat@gmail.com",
  github: "https://github.com/imtoor",
  aboutMe:
    "I am Reynaldi Rahmat, software developer from Makassar, Indonesia. Experience in web and mobile app development, also I am good at wordpress.",
};

function About() {
  return (
    <div className="row">
      <div className="col-md-3">
        <img src={aboutData.image} alt={aboutData.name} />
      </div>
      <div className="col-md-9">
        <h2 className="mt-4 mt-md-0 mb-4">My Bio</h2>
        <p className="mb-0"></p>
        <div className="row my-4">
          <div className="col-md-6">
            <p className="mb-2">
              Name: <span className="text-dark">{aboutData.name}</span>
            </p>
            <p className="mb-0">
              Birthday: <span className="text-dark">{aboutData.birthday}</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2">
            <p className="mb-2">
              Location: <span className="text-dark">{aboutData.location}</span>
            </p>
            <p className="mb-0">
              Email: <span className="text-dark">{aboutData.email}</span>
            </p>
          </div>
          <div className="col-md-6 mt-2 mt-md-0 mt-sm-2 mb-2">
            <p className="mt-2 mb-0">
              Github: <a href={aboutData.github} target="_blank" rel="noreferrer"><span className="text-dark">@imtoor</span></a>
            </p>
          </div>
        </div>
        <a href={aboutData.cvpath} target="_blank" rel="noreferrer" className="btn btn-default mr-3">
          <i className="icon-cloud-download"></i>Download CV
        </a>
        <ScrollLink
          activeClass="active"
          to="section-contact"
          spy={true}
          smooth={true}
          duration={500}
          offset={50}
          className="btn btn-alt mt-2 mt-md-0 mt-xs-2"
        >
          <i className="icon-envelope"></i>Hire me
        </ScrollLink>
      </div>
    </div>
  );
}

export default About;
