import React, { useState, useEffect } from "react";
import Portfolio from "../Items/Portfolio";
import { randomID } from "../../utils/StringUtil";

const filters = [
  {
    id: randomID(),
    name: "All Projects",
  },
  {
    id: randomID(),
    name: "web development",
  },
  {
    id: randomID(),
    name: "app development",
  },
];

const allData = [
  {
    name: "Event Website of Gowa Run 2024",
    category: ["web development"],
    image: "images/portfolio/gowarun-2024.png",
    slug: "event-website-of-gowarun-2024",
  },
  {
    name: "Tundung Mairi Association Organization(PTM) Kota Makassar",
    category: ["web development"],
    image: "images/portfolio/ptm.png",
    slug: "tundung-mairi-association-organization-kota-makassar"
  },
  {
    name: "Company Profile Website of Debindo Makassar",
    category: ["web development"],
    image: "images/portfolio/debindo.png",
    slug: "company-profile-website-of-debindo-makassar",
  },  
  {
    name: "Parliament of West Papua Province Website",
    category: ["web development"],
    image: "images/portfolio/parliament-of-west-papua-province-website.png",
    slug: "parliament-of-west-papua-province-website",
  },
  {
    name: "Large Solar Installs",
    category: ["web development"],
    image: "images/portfolio/large-solar-installs.png",
    slug: "large-solar-installs",
  },
  {
    name: "Online Exam Test(Tryout) with Learning Material for Indonesian CPNS",
    category: ["web development"],
    image: "images/portfolio/cat-cpns-online.png",
    slug: "cat-cpns-online",
  },
  {
    name: "Eco Roads",
    category: ["web development"],
    image: "images/portfolio/eco-roads.png",
    slug: "eco-roads",
  },
  {
    name: "Store Poin",
    category: ["app development"],
    image: "images/portfolio/store-poin.png",
    slug: "store-poin",
  },
  {
    name: "Village Office Benteng Utara Website",
    category: ["web development"],
    image: "images/portfolio/benteng-utara.png",
    slug: "village-office-benteng-utara-website",
  },
  {
    name: "15th National Congress & 44th Annual Scientific Meeting of Indonesia Ophthalmologist Association Registration System",
    category: ["web development"],
    image: "images/portfolio/pit-perdami-sulsel-15th.png",
    slug: "pit-perdami-sulsel-15th",
  },
  {
    name: "Company Profile & Service Price Checking App (AERO CARGO & LOGISTICS)",
    category: ["web development"],
    image: "images/portfolio/aero-logistics.png",
    slug: "aero-logistics-company-profile",
  },
];

function Portfolios() {
  const [getAllItems] = useState(allData);
  const [dataVisibleCount, setDataVisibleCount] = useState(6);
  const [dataIncrement] = useState(3);
  const [activeFilter, setActiveFilter] = useState("");
  const [visibleItems, setVisibleItems] = useState([]);
  const [noMorePost, setNoMorePost] = useState(false);

  useEffect(() => {
    setActiveFilter(filters[0].name.toLowerCase());
    let newItemWithID = [];
    
    getAllItems.forEach( (item, index) => {
      item.id = index ;
      newItemWithID.push(item);
    })
    
    setVisibleItems(newItemWithID.filter((item) => item.id < 9));
  }, [getAllItems]);

  const handleChange = (e) => {
    console.log(e.target.value);
    e.preventDefault();
    let targetFilter = e.target.value
      ? e.target.value.toLowerCase()
      : e.target.textContent.toLowerCase();
    setActiveFilter(targetFilter);
    let tempData;
    if (targetFilter === filters[0].name.toLowerCase()) {
      tempData = getAllItems.filter((data) => data.id <= dataVisibleCount);
    } else {
      tempData = getAllItems.filter((data) => {
        return (
          data.category.includes(targetFilter) && data.id <= dataVisibleCount
        );
      });
    }
    setVisibleItems(tempData);
  };

  const handleLoadmore = (e) => {
    e.preventDefault();
    let tempCount = dataVisibleCount + dataIncrement;

    if (tempCount > getAllItems.length) {
      setNoMorePost(true);
    } else {
      setDataVisibleCount(tempCount);
      if (activeFilter === filters[0].name.toLowerCase()) {
        setVisibleItems(getAllItems.filter((data) => data.id <= tempCount));
      } else {
        let items = getAllItems.filter((data) => {
          return data.category.includes(activeFilter) && data.id <= tempCount;
        });
        setVisibleItems(items);
      }
    }
  };

  return (
    <>
      <ul className="portfolio-filter list-inline">
        {filters.map((filter) => (
          <li
            className={
              filter.name.toLowerCase() === activeFilter
                ? "list-inline-item current"
                : "list-inline-item"
            }
            key={filter.id}
            onClick={handleChange}
          >
            {filter.name}
          </li>
        ))}
      </ul>

      <div className="pf-filter-wrapper mb-4">
        <select
          className="portfolio-filter-mobile"
          onChange={(e) => handleChange(e)}
        >
          {filters.map((filter) => (
            <option value={filter.name} key={filter.id}>
              {filter.name}
            </option>
          ))}
        </select>
      </div>

      <div className="row portfolio-wrapper">
        {visibleItems.map((item) => (
          <div className="col-md-4 col-sm-6 grid-item" key={item.id}>
            <Portfolio portfolio={item} />
          </div>
        ))}
      </div>

      {noMorePost ? null : (
        <div className="load-more text-center mt-4">
          <a
            href="#!"
            className="btn btn-default"
            onClick={(e) => handleLoadmore(e)}
          >
            <i className="fas fa-circle-notch"></i> Load more
          </a>
        </div>
      )}
    </>
  );
}

export default Portfolios;
