import React from "react";
import Experience from "../Items/Experience";
import { randomID } from "../../utils/StringUtil";

const experiencesData = [
  {
    id: randomID(),
    year: "July 2024 - Sept 2024",
    degree: "Fullstack Programmer",
    content: "Digicorp"
  },
  {
    id: randomID(),
    year: "May 2022 - Aug 2022",
    degree: "PROGRAMMER",
    content:
      "PT. SINAR GALESONG MANDIRI",
  },
  {
    id: randomID(),
    year: "May 2021 - Oct 2021",
    degree: "PROGRAMMER",
    content:
      "PT. APTANA CITRA SOLUSINDO",
  },
  {
    id: randomID(),
    year: "Jul 2020 - Dec 2020",
    degree: "FULL STACK DEVELOPER",
    content:
      "PT. DAFEZ CIPTADAYA GLOBAL",
  },
  {
    id: randomID(),
    year: "Feb 2019 - Jul 2020",
    degree: "FULL STACK DEVELOPER",
    content:
      "HD SOLUTION",
  },
  {
    id: randomID(),
    year: "Feb 2018 - Mar 2019",
    degree: "MOBILE APP DEVELOPER",
    content:
      "PERAWAT.ID",
  },
  {
    id: randomID(),
    year: "Apr 2018 - Sep 2018",
    degree: "FULLSTACK DEVELOPER",
    content:
      "PT. ANNAHDAH TEKNOLOGI INDONESIA",
  },
  {
    id: randomID(),
    year: "Aug 2017 - Feb 2018",
    degree: "IT SUPPORT",
    content:
      "CV. KKA",
  },
  {
    id: randomID(),
    year: "Dec 2015 - Feb 2016",
    degree: "FRONT-END WEB DEVELOPER & UML TRAINER",
    content:
      "PT. KARYA UNGGUL NUSANTARA",
  },
  {
    id: randomID(),
    year: "Oct 2013 - Apr 2014",
    degree: "IT SUPPORT",
    content:
      "HARRISMA KOMPUTER",
  },      
];

function Experiences() {
  return (
    <div className="timeline">
      {experiencesData.map((experience) => (
        <Experience experience={experience} key={experience.id} />
      ))}
      <span className="timeline-line"></span>
    </div>
  );
}

export default Experiences;
